<template>
  <v-row v-scroll="onScroll">
    <!-- header -->
    <mini-header title="Grupos" :breadcrumbs="breadcrumbs" :tooltip="`A este grupo pertenecen todos los proveedores de ${breadcrumbs.children[1].text1}`" :dense="true" :hiddenTab="true" :scroll="scroll">
      <template v-slot:main>
        <v-query-builder class="mx-2" :query-map="filters" model="suppliers" :active.sync="activeFilter" />
      </template>
    </mini-header>
    <!-- end header -->

    <v-col cols="12" class="px-0">
      <skeleton-list v-if="loading" />
      <template v-else>
        <v-empty-state v-if="!suppliersList.length" type="suppliers" id="proveedores" customClass="mt-5" :hiddenDescription="true" :isFree="true" title="No existen proveedores asociados al grupo">
        </v-empty-state>
        <template v-else>
          <v-data-table
            :class="[{'fixed-table' : $store.state.base.isExpandNavigationDrawer}]"
            :headers="headers"
            :items="suppliersList"
            :items-per-page="pagination"
            mobile-breakpoint
            hide-default-header
            disable-sort
            hide-default-footer
            item-key="id"
            @click:row="(suppliersList) => {$router.push({name: 'SupplierRetrieveUpdate', params: {id: suppliersList.id}})}"
            style="cursor: pointer"
            :height="heightTable"
            fixed-header
          >
            <!-- header -->
            <template v-slot:header="{ props: { headers } }">
              <VTableHeaders
                :headers="headers"
                @sort="setSort"
              />
              <v-divider style="position: absolute;  margin-top: -6px; margin-left: -5px ;z-index: 2; min-width: 100%" />
            </template>
            <!-- end header -->
            <template v-slot:[`item.name`]="{item}">
              <template v-if="item.name || item.tax_id">
                <span class="body-1 d-inline-block text-truncate" style="max-width: 95%;" v-if="item.name">{{ item.name }}</span>
                <span class="d-block body-2 text--disabled font-italic" v-else>Sin especificar</span>
                <span class="mt-n1 caption fontDraw--text d-block" v-if="item.tax_id">{{$t('generals.RUT')}} {{item.tax_id}}</span>
              </template>
              <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
            </template>
            <template v-slot:[`item.tax_settings`]="{item}">
              <template v-if="item.tax_settings?.cl_activity_description">{{item.tax_settings?.cl_activity_description}}</template>
              <span class="body-2 text--disabled" v-else>Sin especificar</span>
            </template>
            <template v-slot:[`item.created`]="{item}">
              <span class="text-right">{{item.created | dateTimeSecond}}</span>
            </template>
            <template v-slot:[`item.group_assigned_date`]="{item}">
              <span class="text-right" v-if="item.group_assigned_date">{{item.group_assigned_date | dateTimeSecond}}</span>
              <span class="body-2 text--disabled" v-else>Sin especificar</span>

            </template>
            <template v-slot:[`item.actions`]="{item}">
              <v-menu close-on-click close-on-content-click offset-y left :nudge-bottom="10" :nudge-left="10" transition="slide-y-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mr-n4" v-bind="attrs" v-on="on" text x-small :disabled="item?.group_details?.name === 'Bloqueados'"><v-icon color="secondary" size="24">mdi-dots-vertical</v-icon></v-btn>
                </template>
                <v-list class="pa-0">
                  <v-list-item class="px-3" v-if="$helpers.hasSomePermission(['delete_suppliergroup']) && item?.group_details?.name" @click="currentItem = item, dialogDelete = true" :ripple="false">
                    <v-list-item-title class="body-2 d-flex align-center"><v-icon color="secondary" size="16" left>mdi-folder-minus</v-icon>Eliminar del grupo</v-list-item-title>
                  </v-list-item>
                  <v-list-item class="px-3" v-if="$helpers.hasSomePermission(['add_suppliergroup']) && !item?.group_details?.name" @click="currentItem = item, handlerAddGroup()" :ripple="false">
                    <v-list-item-title class="body-2 d-flex align-center"><v-icon color="secondary" size="16" left>mdi-folder-plus</v-icon>Agregar a grupo</v-list-item-title>
                  </v-list-item>
                  <v-list-item class="px-3" v-else @click="currentItem = item, handlerAddGroup()" :ripple="false">
                    <v-list-item-title class="body-2 d-flex align-center"><v-icon color="secondary" size="16" left>mdi-swap-horizontal</v-icon>Cambiar de grupo</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
          <VMainPagination titleShowAll="Ver todos los proveedores" :count="count" redirect="SupplierListCreate" />
        </template>
      </template>
    </v-col>

     <!-- dialog confirm delete -->
     <v-dialog v-model="dialogDelete" width="759" persistent no-click-animation overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="transparent" flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 secondary--text font-weight-semibold">Eliminar del grupo</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogDelete=false" icon small retain-focus-on-click><v-icon color="secondary" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-5 secondary--text">
          <v-card-text class="py-4 px-5 secondary--text">
          <v-row class="pt-2" align="center">
            <v-col cols="3">
              <img :src="require(`@/assets/backgrounds/modal-el-lock.svg`)" :width="168" />
            </v-col>
            <v-col class="pr-4 body-1 pl-8">
              Al eliminar del grupo, el proveedores no tendrá grupos asociados y estará sujetos a las reglas de automatización para proveedores sin grupos.

              <span class="d-block body-1 mt-4 mb-3">Escribe <span class="font-weight-bold">“confirmar”</span> para
                continuar.</span>
              <v-text-field v-model="confirm" outlined single-line maxlength="9" dense hide-details />
            </v-col>
          </v-row>
        </v-card-text>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn @click="dialogDelete=false" outlined>Cancelar</v-btn>
          <v-btn color="primary" @click="destroySuppliertSegment" :disabled="confirm.toLowerCase() !== 'confirmar' ">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog confirm delete -->

    <!-- dialog add to group -->
    <v-dialog v-model="dialogAddGroup" width="850" persistent no-click-animation scrollable overlay-color="overlay">
      <v-card v-if="dialogAddGroup">
        <v-card-title class="pa-0">
          <v-toolbar color="transparent" flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 secondary--text font-weight-semibold">Cambiar de grupo</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogAddGroup=false, group=''" icon small><v-icon size="24">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="pb-7 pt-5 px-5" style="max-height: 600px !important;">
          <v-row class="pl-5" align="center" no-gutters>
            <v-col cols="3" class="pt-2">
              <img :src="require(`@/assets/backgrounds/modal-el-lock.svg`)" :width="160" />
            </v-col>
            <v-col class="pl-3 pr-0">
              <span class="mb-1 d-flex aling-center font-weight-medium text-body-2 secondary--text">
                <v-icon color="secondary" size="16" left>mdi-human-dolly</v-icon>
               {{currentItem.name}}, RUT {{ currentItem.tax_id }}
              </span>
              <v-list class="pa-0 mt-5 transparent v-list-form-extended">
                <v-list-item class="py-1">
                  <v-list-item-title>
                    <v-row no-gutters>
                      <template>
                        <v-col class="pa-0 pr-1">
                          <span class="mb-1 d-flex aling-center">
                            <v-icon color="secondary" size="16" left>mdi-folder</v-icon>
                            Grupo Actual
                          </span>
                          <v-text-field
                            v-model="currentGroup"
                            hide-details
                            single-line
                            color="primary"
                            dense
                            outlined
                            disabled
                          />
                        </v-col>
                        <div style="width: 30px !important" class="pl-1 mt-6">
                          <v-icon color="secondary" size="24" left>mdi-swap-horizontal</v-icon>
                        </div>
                      </template>
                      <v-col class="pa-0 pl-1">
                        <span class="mb-1 d-flex aling-center">
                          <v-icon color="secondary" size="16" left>mdi-folder</v-icon>
                           Nuevo grupo
                        </span>
                        <v-select
                          v-model="group"
                          hide-details
                          single-line
                          color="primary"
                          dense
                          outlined
                          :items="groupsList"
                          :chips="false"
                          item-text="name"
                          item-value="id"
                          placeholder="Selecciona el nuevo grupo"
                          no-data-text="No existen grupos para mostrar"
                        />
                      </v-col>
                    </v-row>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-5 py-2">
          <v-spacer/>
          <v-btn outlined @click="dialogAddGroup=false, group=''">Cancelar</v-btn>
          <v-btn color="primary" @click="addToGroup" :loading="createGroupLoading">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog add to group -->

  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import VEmptyState from '@/components/commons/VEmptyState'
import MiniHeader from '@/components/commons/MiniHeader'
import VQueryBuilder from '@/components/VQueryBuilder/VQueryBuilder'
import SkeletonList from '@/components/commons/skeletonLoader/SkeletonList'
import VTableHeaders from '@/components/VTable/VTableHeaders'
import ListViewMixin from '@/mixins/ListViewMixin'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import VMainPagination from '@/components/commons/VMainPagination'

export default {
  components: {
    VQueryBuilder,
    VTableHeaders,
    SkeletonList,
    VMainPagination,
    MiniHeader,
    VEmptyState
  },
  mixins: [
    GenericViewMixin,
    ListViewMixin
  ],
  data: () => ({
    currentGroup: '',
    group: '',
    createGroupLoading: false,
    dialogAddGroup: false,
    currentItem: {},
    menu: [false],
    idDelete: '',
    dialogDelete: false,
    activeFilter: false,
    scroll: 0,
    confirm: '',
    isAccept: false,
    dialogConfirm: false,
    current: {},
    filters: [
      {
        type: 'text',
        id: 'name',
        label: 'Nombre',
        value: null,
        category: 'a',
        operators: ['contiene']
      },
      {
        type: 'text',
        id: 'tax_id',
        label: 'RUT',
        value: null,
        category: 'a',
        operators: [],
        countries: ['PE', 'CL']
      }
    ],
    headers: [
      { text: 'Proveedor', value: 'name', class: 'font-weight-bold', sortable: true },
      { text: 'RUT', value: 'tax_id', sortable: true },
      { text: 'Actividad económica', value: 'tax_settings', sortable: true },
      { text: 'Fecha de creación', value: 'created', align: 'end', sortable: true },
      { text: 'Ingreso  a grupo', value: 'group_assigned_date', align: 'end', sortable: true },
      { text: '', value: 'actions', align: 'end'}
    ],
    dialogSupplier: false,
    loading: false,
    breadcrumbs: {
      main: 'Recepción',
      children: [
        {
          text: 'Grupos',
          route: 'SuppliersGroupsList'
        },
        {
          text1: ''
        }
      ]
    }
  }),
  computed: {
    ...mapState({
      count: state => state.suppliers.suppliersCount,
      suppliersList: state => state.suppliers.suppliersList,
      countriesList: state => state.base.countriesList,
      membersList: state => state.accounts.membersList,
      groupsList: state => state.suppliers.groupsList
    }),
    heightTable () {
      if (this.$vuetify.breakpoint.height >= 800 && this.suppliersList.length < 17) return 'auto'
      return 'calc(100vh - 210px)'
    }
  },
  watch: {
    'newSupplier.country' () {
      this.newSupplier.state = ''
      this.newSupplier.tax_id = ''
    }
  },
  created () {
    this.getList()
  },
  methods: {
    handlerAddGroup () {
      this.currentGroup = this.currentItem.group_details?.name ? this.currentItem.group_details.name : 'Sin grupo asociado'
      this.$store.dispatch('suppliers/LIST', {
        resource: 'suppliers/groups',
        query: {
          page_size: 9999
        }
      })
      this.dialogAddGroup = true
    },
    addToGroup () {
      this.createGroupLoading = true
      let currentQuery = JSON.parse(JSON.stringify(this.$route.query))
      if (currentQuery.ordering) delete currentQuery.ordering
      this.$store.dispatch('suppliers/UPDATE', {
        resource: 'suppliers',
        id: this.currentItem.id,
        query: {
          ...currentQuery
        },
        payload: {
          group: this.group
        }
      })
      .then(() => {
        this.$dialog.message.info('El proveedor ha sido asignado correctamente al grupo.')
        this.getList()
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.groups = ''
        this.dialogAddGroup = false
        this.createGroupLoading = false
      })
    },
    onScroll (e) {
      this.offsetTop = e.target?.scrollingElement?.scrollTop ?? 0
    },
    destroySuppliertSegment (id = '') {
      this.$store.dispatch('suppliers/UPDATE', {
        resource: 'suppliers',
        id: this.currentItem.id,
        payload: {
          group: null
        }
      })
      .then(() => {
        this.$dialog.message.info('El proveedor se ha sido eliminado con éxito')
        this.getList()
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.idDelete = ''
        this.confirm = ''
        this.dialogDelete = false
      })
    },
    getList () {
      this.breadcrumbs.children[1].text1 = ''
      this.loading = true
      let currentQuery = JSON.parse(JSON.stringify(this.$route.query))
      if (currentQuery.name) delete currentQuery.name
      this.$store.dispatch('suppliers/LIST', {
        resource: 'suppliers',
        query: {
          group: this.$route.params.id,
          ...currentQuery
        }
      })
      .finally(() => {
        this.loading = false
      })
      this.$store.dispatch('suppliers/RETRIEVE', {
        resource: 'suppliers/groups',
        id: this.$route.params.id
      })
      .then((response) => {
        this.breadcrumbs.children[1].text1 = response.data?.name ?? 'Sin grupo asociado'
      })
      .catch(() => {
        if (!this.breadcrumbs.children[1].text1) this.breadcrumbs.children[1].text1 = 'Sin grupo asociado'
      })
    }
  }
}
</script>